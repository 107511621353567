<template lang="pug">
main.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/overscans/fitness.jpg')",headline="An exclusive and bespoke fitness experience awaits you")
	.container
		//- full-image-section(style="padding-top:5rem",:img='require("@/assets/img/real_site_images/resized/swimming_pool2.jpg")',alt="swag")

		right-side-section(
			title="The Gym"
			:img='require("@/assets/img/real_site_images/resized/fitness centre_1.jpg")'
			description="The Health Zone caters to everyone from those new to exercise, the fitness conscious, to the performance athletes. At Time Square Club, it’s not just about weight loss or building muscle. We take a holistic approach to health and fitness by developing an exclusive program for total body wellness."
		)

		left-side-section(
			title="Yoga"
			:img='require("@/assets/img/final_3d_images/low/img065.jpg")'
			description="It is a very ancient discipline, born in regions that today make up northern India. Time Square Club’s aims to help individuals lead a happier, more natural life and so discover how to evolve spiritually. It believes that physical and psychological wellbeing are enhanced by coming to know oneself at all levels – body, breath, emotions, mind and beyond. originally it was an ascetic and meditative practice. It serves to maintain control over one's sensations and drives, to recirculate vital energy with the movement of the body, to harmonize both the body and the mind, and the individual with the universe and its energy, through meditation."
		)
		
		right-side-section(
			title="Aerobics & Zumba"
			:img='require("@/assets/img/final_3d_images/low/img031.jpg")'
			description="It is a dance that mixes Caribbean and Cuban rhythms, steps of merengue and salsa and fitness movements for an aerobic lesson that involves all the muscles of the body. It is therefore a low impact work that stimulates the cardiovascular system and also allows toning and losing weight. The lessons are held in groups, under the guidance of an instructor who performs, step by step, a complete choreography." )

		full-image-section(:img='require("@/assets/img/real_site_images/resized/fitness centre_2.jpg")',alt="Gym Overview")

</template>

<script>
import DisplayHeader from '../components/DisplayHeader.vue'
import RightSideSection from '../components/XRightSideSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import FullImageSection from '../components/XFullImageSection.vue'

export default {
	components: {
	DisplayHeader,
    RightSideSection,
    LeftSideSection,
	FullImageSection
  },
}
</script>

<style>
</style>